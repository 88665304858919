.timeline-item {
    border-left: 0.25em solid var(--shadow-color);
    padding-left: 0.75em;
}

.duration {
    background-color: var(--shadow-color);
    color: var(--background-color) !important;
    padding: 0.5em;
    font-size: 0.7em;
    font-weight: bolder;

    /* on smaller screens this is left aligned, but larger ones have right aligned */
    margin-left: calc(var(--gap-timeline-duration) * -1) !important;
    padding-left: var(--gap-timeline-duration) !important;
  }

.timeline-content {
  clear: both;
  margin-top: 1em;
}

.timeline-title {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.timeline-img-column {
  clear: both;
  padding: 0;
}

.timeline-img {
    max-width: 50%;
    min-width: 50%;
    padding: 2%;
    height: auto;
}

.timeline-description {
  margin-top: 0.25em;
  margin-bottom: 0 !important;
  padding-bottom: 1em;
  font-size: 0.9em;
}


@media (min-width: 1280px) {
  /* right oriented on larger screens */
  .timeline-item {
    border-left: 0;
    padding-left: 0;
    border-right: 0.25em solid var(--shadow-color);
    padding-right: 0.75em;
  }

  .duration {
    float: right;
    padding: 0.1em;
    padding-left: 0.5em;
    margin-right: calc(var(--gap-timeline-duration) * -1) !important;
    padding-right: var(--gap-timeline-duration) !important;
  }

  .timeline-content {
    margin-top: 0;
  }

  .timeline-content-column {
    margin-top: -1.5em;
  }

  .timeline-img-column {
    margin-top: -1.5em;
    padding-right: 1em;
    padding-bottom: 1em;
  }

  .timeline-title {
    font-size: 1em;
    margin-bottom: 0;
  }

  .timeline-description {
    font-size: 0.8em;
    line-height: 1.6;
  }

  .timeline-subtitle {
    font-size: 0.8em;
  }

.timeline-img {
    min-width: 90%;
    max-width: 90%;
    padding: 5%;
  }
}

:root {
  --gap-timeline-duration: 1.25em;
}