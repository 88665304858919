.footer {
  background-color: var(--shadow-color);
  color: var(--background-color);
  height: 10vh;

  padding: 3vh;
  text-align: center;
}

#bg-source {
  font-size: 0.6em;
}

.love {
  color: #B91C1C; /* Blood red */
}

.love a:hover {
  color: #B91C1C; /* Blood red */
}

.plain-jane,
#bg-source {
  color: var(--background-color) !important;
}

.plain-jane a:hover,
#bg-source a:hover {
  text-decoration: none;
}

@media (min-width: 1280px) {
  .footer {
      font-size: 0.7em;
  }
}