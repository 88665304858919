.App {
  text-align: center;
}

.contain {
  font-size: calc(10px + 2vmin);
  color: white;

  padding-left: 0 !important;
  padding-right: 0 !important;
}

.preloader {
  width: 100%;
}

#superset {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-image: var(--background-image);

  min-height: 100vh;
  margin: 0;

  padding-top: 10vh;
  padding-left: 0;
  padding-bottom: 2vh; /* appears above footer */
  text-align: initial;
}

@media (min-width: 1280px) {
  #superset {
    padding-left: 20vh;
    padding-right: 10vh;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    background: var(--background-color); 
  }

  ::-webkit-scrollbar-thumb {
    background: var(--shadow-color); 
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--text-color); 
  }
}

/* Themes - name must match - only include background images here */
.sakura{
  --background-image: url('./images/sakura.jpg');
}

.waves{
  --background-image: url('./images/Waves.jpg');
}

.train{
  --background-image: url('./images/train.jpeg');
}

.mountain{
  --background-image: url('./images/mountains.jpeg');
}

.beach{
  --background-image: url('./images/beach.jpeg');
}

.snow{
  --background-image: url('./images/snow.jpeg');
}

.plant{
  --background-image: url('./images/plant.jpeg');
}

.palm {
  --background-image: url('./images/palm.png');
}

:root {
  --left-padding: 4vh;
}
